<template>
  <div class="wrap animated">
    <b-card header="그룹 상세보기 & 메뉴 수정">
      <strong>{{ this.$route.query.groupName }} 그룹의 등록된 메뉴</strong>
      <b-tabs pills card vertical>
        <b-tab :active="index === 0" v-for="(item, index) in this.authGroupMenu.menuList">
          <template slot="title">
            {{item.upperMenu.menuName}}
          </template>
          <ul v-if="item.lowerMenuList.length > 0">
            <li v-for="(lowerMenu, index) in item.lowerMenuList">
              {{lowerMenu.menuName}}
              <b-link @click="removeMenu(lowerMenu)" class="mod ml-3">[삭제]</b-link>
            </li>
          </ul>
          <span v-else>하위 메뉴 없음</span>
        </b-tab>
      </b-tabs>

      <div class="mt-5">
        <strong>등록되지 않은 메뉴</strong>
        <ul v-for="upperMenu in menuListNotInGroupForView">
          <strong>{{upperMenu.menuName}}</strong>
          <li v-for="subMenu in upperMenu.children" class="ml-3">
            {{subMenu.menuName}} <b-link @click="addMenu(subMenu)">[ 추가 ]</b-link>
          </li>
        </ul>
      </div>

    </b-card>
  </div>
</template>
<script>
  import AuthService from '@/common/api/auth.service'
  import { mapMutations } from 'vuex'
  import { SHOW_MODAL } from '@/store/ui/mutations.type'
  import { $showModalWithErrorMsg } from '@/common/util'
  export default {
    data () {
      return {
        authGroupMenu: {
          authGroup: {

          },
          menuList: []
        },
        menuListNotInGroupForView: []
      }
    },
    mounted () {
      this.getData()
    },
    methods: {
      addMenu (subMenu) {
        let menuDto = {
          authGroupId: this.$route.query.groupId
        }

        AuthService.addMenuToGroup({...menuDto, ...subMenu})
          .then(({data})=> {
            this.getData()
          })
          .catch($showModalWithErrorMsg.bind(this))
      },
      removeMenu (subMenu) {
        let menuDto = {
          authGroupId: this.$route.query.groupId
        }

        AuthService.removeMenuFromGroup({...menuDto, ...subMenu})
          .then(({data})=> {
            this.getData()
          })
          .catch($showModalWithErrorMsg.bind(this))

      },
      submitHandling() {
        // AuthService.createGroup({
        //   name: this.groupName
        // }).then(({data})=>{
        //   this[SHOW_MODAL]({msg: '등록이 완료되었습니다.', okHandling: () => {}})
        // }).catch((err)=> {
        //     if(err.response.data.code === 'authGroup.already') {
        //       // 이렇게 한 이유는 나중에 다국어 지원할려고..
        //       this[SHOW_MODAL]({msg: '이미 존재하는 그룹명입니다.', okHandling: () => {}})
        //     } else {
        //       this[SHOW_MODAL]({msg: err.response.data.msg, okHandling: () => {}})
        //     }})
        //   .catch($showModalWithErrorMsg.bind(this))
      },
      getData () {
        AuthService.getMenuListByGroup(this.$route.query.groupId)
          .then(({data})=> {
            let menuListNotInGroupForView = [];
            for (let i = 0; i < data.menuNotInGroup.length; i++) {
              if (data.menuNotInGroup[i].titleYn) {
                let upperMenu = data.menuNotInGroup[i]
                upperMenu.children = []

                menuListNotInGroupForView.push(upperMenu)

                for(let j = 0; j < data.menuNotInGroup.length; j++) {
                  if (i === j) {
                    continue
                  }
                  if (upperMenu.menuId === data.menuNotInGroup[j].upperId) {
                    menuListNotInGroupForView[menuListNotInGroupForView.length -1].children.push(data.menuNotInGroup[j])
                  }
                }
              }
            }
            this.menuListNotInGroupForView = menuListNotInGroupForView

            if (data.authGroupMenu !== null) {
              this.authGroupMenu = data.authGroupMenu
            }
          })
          .catch($showModalWithErrorMsg.bind(this))
      },
      ...mapMutations('ui', [SHOW_MODAL])
    }
  }
</script>
<style scoped>
</style>
