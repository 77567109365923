import {EMBED_URL, SHARE_URL, REG_SHARE_MOVIE_ADDR, REG_EMBED_MOVIE_ADDR, INHOUSE} from '@/constants'
import {SHOW_MODAL} from '@/store/ui/mutations.type'
export function removeArrElementByValue (arr, value) {
  const foundIndex = arr.indexOf(value)
  if (foundIndex !== -1) {
    arr.splice(foundIndex, 1)
  } else {
    throw Error('배열에서 해당 값을 찾을 수 없습니다.')
  }
}
export function validationTemplate (fnStrategy) {
  if (!this.getIsTrySubmit()) {
    return null
  }
  if (fnStrategy()) {
    return false
  }
  return true
}
export function calcCharacterByte (strInput) {
  if (strInput === undefined) {
    return 0
  }
  let sumBytes = 0
  for (let i = 0; i < strInput.length; i++) {
    let ch = strInput.charAt(i)
    if (escape(ch).length > 4) {
      sumBytes += 2
    } else {
      sumBytes++
    }
  }
  return sumBytes
}
export function convertUnixTimeToDate (unixTime) {
  return new Date(unixTime)
}
export function $getTypeName (type) {
  for (let i = 0; i < this.codes.pregType.length; i++) {
    if (this.codes.pregType[i].code === type) {
      return this.codes.pregType[i].desc
    }
  }
  return 'unknwon'
}
export function $getLinkedSvcType (type) {
  if (!this.codes.linkedSvcType) return 'unknwon'

  for (let i = 0; i < this.codes.linkedSvcType.length; i++) {
    if (this.codes.linkedSvcType[i].code === type) {
      return this.codes.linkedSvcType[i].desc
    }
  }
  return 'unknwon'
}
export function $getStatusName (status) {
  if (!this.codes.adStatus) return 'unknwon'

  for (let i = 0; i < this.codes.adStatus.length; i++) {
    if (this.codes.adStatus[i].code === status) {
      return this.codes.adStatus[i].desc
    }
  }
  return 'unknwon'
}
export function $getMarketListString (marketInfoList) {
  let result = []
  for (let i = 0; i < marketInfoList.length; i++) {
    for (let j = 0; j < this.codes.marketType.length; j++) {
      if (this.codes.marketType[j].code === marketInfoList[i].marketType) {
        result.push(this.codes.marketType[j].code)
      }
    }
  }
  result.sort()
  return result.join('/')
}
export function $showModalWithErrorMsg (error, okHandling = () => {}) {
  const errorMsg = getAxiosErrorMessage(error)
  const errorHandling = $getErrorHandling.call(this, error)
  this[SHOW_MODAL]({msg: errorMsg, okHandling: errorHandling})
}
// 다국어용
export function shareUrlToEmbedUrl (movieObj, movieObjState) {
  let failLangs = [];
  let nonState = new Set(['none', 'n', false]);

  for (let lang in movieObj) {
    if (movieObj.hasOwnProperty(lang)) {
      let isShareUrl = false;
      if (movieObjState == null || (movieObjState[lang] != null && !nonState.has(movieObjState[lang]))) {
        for (let key in REG_SHARE_MOVIE_ADDR) {
          if (REG_SHARE_MOVIE_ADDR.hasOwnProperty(key) &&
              REG_SHARE_MOVIE_ADDR[key].test(movieObj[lang])) {
            movieObj[lang] = EMBED_URL[key] + '/' + REG_SHARE_MOVIE_ADDR[key].exec(movieObj[lang])[2]

            addEmbedParameter(movieObj, lang);
            isShareUrl = true;
          }
        }
        if (!isShareUrl) {
          failLangs.push(lang);
        }
      }
    }
  }

  return failLangs;
}
// 다국어용
export function embedUrlToShareUrl (movieObj) {
  for (let lang in movieObj) {
    for (let key in REG_EMBED_MOVIE_ADDR) {
      if (REG_EMBED_MOVIE_ADDR[key].test(movieObj[lang])) {
        movieObj[lang] = SHARE_URL[key] + '/' + REG_EMBED_MOVIE_ADDR[key].exec(movieObj[lang])[1]
      }
    }
  }
}
// 다국어용
export function getMediaByShareUrl (movieObj) {
  let media = {}
  for (let lang in movieObj) {
    // 동영상 url이 설정되지 않았을 때
    if (movieObj[lang].trim() === '') {
      media[lang] = 'none'
      continue
    }
    // key 는 kakaotv, youtube
    for (let key in REG_SHARE_MOVIE_ADDR) {
      if (REG_SHARE_MOVIE_ADDR[key].test(movieObj[lang])) {
        media[lang] = key
      }
    }

    // kakaotv나 youtube 설정이 아닐 때는 기타
    if (typeof media[lang] === 'undefined') {
      media[lang] = 'etc'
    }
  }
  return media
}
export function addEmbedParameter (movieObj, lang) {
  const rel = 0
  const controls = 1
  const showinfo = 0
  const parameters = [`rel=${rel}`, `controls=${controls}`, `showinfo=${showinfo}`]
  movieObj[lang] += '?' + parameters.join('&')
}
export function getAxiosErrorMessage (error) {
  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx
  if (error.response) {
    if (error.response.status === 401) {
      return error.response.data.msg
    }
    if (error.response.status >= 400 && error.response.status < 500) {
      return '입력 값을 확인해주세요.'
    } else {
      return '서버에 오류가 발생했습니다.'
    }
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else if (error.request) {
    return '네트워크에 문제가 있습니다.'
  } else {
    // Something happened in setting up the request that triggered an Error
    return '잘못된 방법의 요청입니다.'
  }
}
export function $getErrorHandling (error) {
  if (error.response.status === 401) {
    return () => {
      this.$router.push({
        name: 'login'
      })
    }
  }
  return () => {}
}
export function limitInputByMaxByte (newVal, obj, dtoProperty, maxBytes = 65) {
  let i = 0
  let b = 0
  let c = 0

  for (b = i = 0;c = newVal.charCodeAt(i);) {
    b += c >> 7 ? 2 : 1
    if (b > maxBytes) {
      break
    }
    i++
  }

  obj[dtoProperty] = newVal.substring(0, i)
}
export function noDropAndDropOver () {
  window.addEventListener('dragover', function (e) {
    e = e || event
    e.preventDefault()
  }, false)
  window.addEventListener('drop', function (e) {
    e = e || event
    e.preventDefault()
  }, false)
}
export function $isInhouseLogin () {
  return this.auth.authType === INHOUSE
}
export function addProperty (obj, property, addingValue) {
  if (Array.isArray(obj[property])) {
    obj[property].push(addingValue)
  } else {
    obj[property] = addingValue
  }
}
export function getDefaultValueByType (value) {
  const type = typeof value
  switch (type) {
    case 'boolean': return false
    case 'function': return function () {}
    case 'null': return null
    case 'number': return 0
    case 'object': return {}
    case 'string': return ''
    case 'undefined': return void 0
  }
}
export function removeProperty (obj, property, removeValue) {
  if (Array.isArray(obj[property])) {
    try {
      removeArrElementByValue(obj[property], removeValue)
    } catch (e) {
      console.log(e)
    }
  } else {
    if (removeValue === undefined || obj[property] === removeValue) {
      obj[property] = getDefaultValueByType(obj[property])
    }
  }
}
// 이미지 전송 완려될때 트리거되는 이벤트의 핸들링 메소드
export function $imgSuccessHandling (file, response) {
  file.pregDtoParam.uploadUrl = response.desc
  this.imgHandlingTemplate(file, this.addProperty)
}
// 필요하다면 여기서 remove 요청을 보낸다.
export function $imgRemove (file) {
  this.imgHandlingTemplate(file, this.removeProperty)
}
export function renameAndSetDtoProperty (objDtoParam, file) {
  const objDtoParamDefault = {
    lang: '',
    isMultiple: false,
    name: '',
    // imgSuccesHandling에서 추가됩니다.
    uploadUrl: ''
  }
  file.pregDtoParam = Object.assign({}, objDtoParamDefault, objDtoParam)
  return objDtoParam.name + '.' + file.type.split('/')[1]
}

export function thumbhnailHandlingForLimtDismension(file, dataUrl) {
  if(typeof file.limitByDimension === 'function') {
    file.limitByDimension(file.width, file.height)
  }
}

export function acceptHandlingForDimensionLimit (width, height, name, file, done) {
  let limitByDimension = (fileWidth, fileHeight) => {
    const reason = name + ' 사이즈 가이드를 다시 확인하세요. (' + width + 'x' + height + ')'
    if (fileWidth !== width || fileHeight !== height) {
      done(reason)
    } else {
      done()
    }
  }
  file.limitByDimension = limitByDimension
}

// dimemsionObjList는 아래와 같은 형태다
// [
//    {
//      width: xxxx,
//      height: yyyyy
//    },
//  ]
// 리스트 중에 하나만 만족하면 된다.
export function acceptHandlingForDimensionLimitByObj (dimensionObjList, name, file, done) {
  let limitByDimension = (fileWidth, fileHeight) => {
    let reason = name + ' 사이즈 가이드를 다시 확인하세요. ( '
    for (let i = 0; i < dimensionObjList.length; i++) {
      reason += dimensionObjList[i].width + 'x' + dimensionObjList[i].height

      if ( i + 1 < dimensionObjList.length) {
        reason += ', '
      }
    }
    reason += ' )'

    let isPass = false
    for (let i = 0; i < dimensionObjList.length; i++) {
      if (dimensionObjList[i].width === fileWidth && dimensionObjList[i].height === fileHeight) {
        isPass = true
      }
    }

    if (isPass) {
      done()
    } else {
      done(reason)
    }
  }
  file.limitByDimension = limitByDimension
}

export function checkHexColor(str) {
  let regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i;
  return regex.test(str)
}
export function checkUrl(str) {
  // pgm-api에서 사용 중인 regex
  return /^(https?):\/\/([^:\/\s]+)(:([^\/]*))?((\/[^\s/\/]+)*)?\/?([^#\s\?]*)(\?([^#\s]*))?(#(\w*))?$/.test(str);
}
export function validationURL (url) {
  return /^http(s)?:\/\/(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(url);
}
