<template>
  <div class="wrap">
    <b-card>
      <div slot="header">
        <strong>아틀라스 캐쉬 지우기</strong>
      </div>
      <b-button type="button" @click="asyncAtlas">아틀라스 캐쉬 지우기</b-button>
    </b-card>
  </div>
</template>
<script>
  import CacheEvictService from '@/common/api/evict.service'
  import { $showModalWithErrorMsg } from '@/common/util'
  import { SHOW_MODAL } from '@/store/ui/mutations.type'
  import { mapMutations } from 'vuex'
  export default {
    data () {
      return {
      }
    },
    methods: {
      asyncAtlas () {
        CacheEvictService.evictAtlasCache()
          .then( (res) => {
            this[SHOW_MODAL]({msg: res.data.msg, okHandling: () => {}})
          })
          .catch($showModalWithErrorMsg.bind(this))
      },
      ...mapMutations('ui', [SHOW_MODAL])
    }
  }
</script>
<style scoped>
</style>
